
import { defineComponent, ref } from 'vue'
import { getTableData } from '@/services/tableService'
import type { TableHeaders } from '@/definitions/shared/types'
import TmEmptyState from '@/components/shared/states/TmEmptyState.vue'
import AuditLogsFilter from '@/components/pages/reporting/auditLogs/AuditLogsFilter.vue'
import AuditLogsTable from '@/components/pages/reporting/auditLogs/AuditLogsTable.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import { useModes } from '@/compositions/modes'
import type { ReportingAuditLogs } from '@/definitions/reporting/types'

export default defineComponent({
  components: {
    TmEmptyState,
    AuditLogsTable,
    AuditLogsFilter,
    WhiteBlock,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const expand = ref(false)
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Date', value: 'date-slot', width: '20%' },
      { text: 'Account', value: 'account-slot', width: '20%' },
      { text: 'Category', value: 'category-slot', width: '20%' },
      { text: 'Action', value: 'action-slot', width: '40%', expandPossible: true },
    ])
    const tableItems = ref<ReportingAuditLogs[]>(getTableData('reportingAuditLogs').map((el: any) => ({
      ...el,
      account: {
        uid: '1224ddc9-27aa-454d-a6e0-cbbebefaa156',
        firstName: 'Ruth',
        lastName: 'Adkins',
        fullName: 'Ruth Adkins',
        avatar: 'https://randomuser.me/api/portraits/women/15.jpg',
        avatarColor: 'green',
      },
    })))

    return {
      isEmptyMode,
      expand,
      tableHeaders,
      tableItems,
    }
  },
})
